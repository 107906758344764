import { headerMobileToggle } from './header.js';
import { contactSubmit } from './contact.js';
import { JoinSubmit } from './join_us.js';
import {maps} from './map.js';

if (document.getElementById('mobile_menu_toggle')) {
	headerMobileToggle();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('join_form')) {
	JoinSubmit();
}

if(document.getElementById('map')) {
	maps();
}
if (document.querySelector('.book_type')) {
	document.querySelectorAll('.book_type').forEach((bookType, index) => {
		bookType.getAttribute('data-book') === "0" ? bookType.classList.add('active') : null;
		document.querySelectorAll('.book_ctn').forEach((bookCtn) => {
			bookCtn.getAttribute('data-book') === "0" ? bookCtn.classList.add('active') : null;
		});
		bookType.addEventListener('click', () => {
			document.querySelectorAll('.book_type').forEach((bookType) => bookType.classList.remove('active'));
			document.querySelectorAll('.book_ctn').forEach((bookCtn) => {
				bookCtn.getAttribute('data-book') === index.toString() ? bookCtn.classList.add('active') : bookCtn.classList.remove('active');
			});
			bookType.classList.add('active');
		});
	});
}

/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

export const maps = () => {

	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.getElementById('map'),
				locationElementSelector: ('.locs_content'),
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
						<a class="map_pin" href="#card${el.getAttribute('index')}">
							<img src="/dist/images/icons/map_pin.svg" alt="map pin" />
							<div><span>${el.getAttribute('index')}</span></div>
						</a>
						`
					};
				}
			});

			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				if (document.querySelectorAll('.locs_content').length < 2) {
					theMap.setZoom(15);
				}
				document.querySelectorAll('.locs_content').forEach(i => {
					const markerData = mapHelper.getMarker(i);
					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						i.classList.add('hover');
					});
					markerData.element.addEventListener('mouseleave', () => {
						markerData.element.classList.remove('hover');
						i.classList.remove('hover');
					});
				});
			});
		});
};
export const headerMobileToggle = () => {
	const menuToggle = document.getElementById('mobile_menu_toggle');
	const headerNav = document.querySelector('.header_nav');

	menuToggle.addEventListener('click', function () {
		if (menuToggle.classList.contains('open')) {
			menuToggle.classList.remove('open');
		} else {
			menuToggle.classList.add('open');
		}

		if (headerNav.classList.contains('open')) {
			headerNav.classList.remove('open');
		} else {
			headerNav.classList.add('open');
		}
	});

};